<template>
    <div>
        <v-card flat style="background-color: var(--v-background-base) !important">
            <v-toolbar color="greyBase" dark :extended="!$vuetify.breakpoint.mobile" flat extension-height="55">
                <v-toolbar-title>
                    <b class="mr-3">Admin Panel</b>
                </v-toolbar-title>
            </v-toolbar>

            <v-card class="mx-auto" :max-width="!$vuetify.breakpoint.mobile ? '94vw' : '100%'" :style="{
                'margin-top': !$vuetify.breakpoint.mobile ? '-64px' : '0px',
                'border-radius': !$vuetify.breakpoint.mobile ? '50px' : '0px',
            }">
                <v-card-text :style="{
                    height: !$vuetify.breakpoint.mobile ? '90vh' : '90vh',
                    'overflow-y': 'auto',
                }">
                    <v-tabs show-arrows v-model="activeTab" height="40px" class="mb-1 pb-1 mt-0 pt-0">
                        <v-tab v-for="tab in tabs" :key="tab" :href="`#${tab}`">
                            {{ tab }}
                        </v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="activeTab">
                        <v-tab-item value="Subscriptions">
                            <Subscription />
                        </v-tab-item>
                        <v-tab-item value="Billing">
                            <Billing />
                        </v-tab-item>
                        <v-tab-item value="Modules">

                            <Modules />
                        </v-tab-item>
                        <v-tab-item value="Scopes">
                            <Scopes />
                        </v-tab-item>
                        <v-tab-item value="Routes">
                            <Routes />
                        </v-tab-item>
                        <v-tab-item value="Users">
                            <Users />
                        </v-tab-item>
                        <v-tab-item value="Tags">
                            <Tags />
                        </v-tab-item>
                        <v-tab-item value="Models">
                            <Models />
                        </v-tab-item>
                        <v-tab-item value="System Variables">
                            <SystemVariables />
                        </v-tab-item>
                        <v-tab-item value="Forms">
                            <v-card class="my-0 py-0">
                                <v-card-text class="my-0 py-0">
                                    <Forms />
                                </v-card-text>
                            </v-card>
                        </v-tab-item>
                        <v-tab-item value="EDIFACT">
                            <EdiFacts />
                        </v-tab-item>
                        <v-tab-item value="Guides">
                            <Guides />
                        </v-tab-item>
                    </v-tabs-items>
                </v-card-text>
            </v-card>
        </v-card>
    </div>
</template>

<script>
import Billing from '../components/Admin/Billing.vue'

import Forms from '../components/Admin/Forms.vue'

import Models from '../components/Admin/Models.vue'
import Modules from '../components/Admin/Modules.vue'
import Routes from '../components/Admin/Routes.vue'
import Scopes from '../components/Admin/Scopes.vue'
import Users from '../components/Admin/Users.vue'
import Subscription from '../components/Admin/Subscription.vue'
import SystemVariables from '../components/Admin/SystemVariable.vue'
import Tags from '../components/Admin/Tags.vue'
import EdiFacts from '../components/Admin/EdiFacts.vue'
import Guides from '../components/Admin/Guides.vue'
export default {
    components: {
        Billing,
        Forms,
        Models,
        Modules,
        Routes,
        Scopes,
        Subscription,
        SystemVariables,
        Tags,
        Users,
        EdiFacts,
        Guides,
    },
    data: () => ({
        activeTab: 0,
        loaded: true,
        tabs: ['Subscriptions', 'Forms', 'Modules', 'Scopes', 'Routes', 'Users', 'Models', 'System Variables', 'Tags', 'Billing', 'EDIFACT', 'Guides']
    }),

}
</script>
